<template>
  <section>
    <p class="pb-2 border-lightGray-b mb-4 font-weight-title">量多優惠</p>
    <div>
      <!-- <v-row no-gutters>
        <v-col cols="3">
          <div class="px-4 py-2 text-center">數量</div>
        </v-col>
        <v-col cols="9">
          <div class="px-4 py-2 text-center">價格</div>
        </v-col>
      </v-row> -->

      <div class="border-lightGray-all border-radius-primary">
        <discount-item
          v-for="(config, index) in configs"
          :config="config"
          :product="product"
          :index="index"
          :length="configs.length"
          :key="index"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    configs: Array,
    product: Object,
  },
  components: {
    "discount-item": () => import("./discountItem.vue"),
  },
};
</script>